<template>
  <div class="acesso-rapido container">
    <div class="row1">
      <div class="consulta">
        <p>Consulta de servidores</p>
        <b-form-input type="search"></b-form-input>
      </div>
      <div class="consignacoes">
        <p class="label">Consignações (3 ultimos dias)</p>
        <p class="data">20</p>
      </div>
    </div>
    <div class="row2">
      <div class="nova">Nova Consignação</div>
      <div class="importacao">Importação de Arquivos</div>
      <div class="propostas">Propostas de Consignação</div>
    </div>
  </div>
</template>
<script>
export default {

}
</script>
<style :scoped>
.row1, .row2{
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
}
.consulta{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 12px rgba(222, 222, 231, 0.4);
  border-radius: 12px;
  padding: 10px;
  width: 60%;
}
.consignacoes{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 12px rgba(222, 222, 231, 0.4);
  border-radius: 12px;
  padding: 10px;
  width: 30%;
}
.label{
  font-size: 0.7em;
}
.nova, .importacao, .propostas{
  width: 30%;
  height: 112px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding: 10px;
}

.nova{
  background: #E0F4F4;
  color: #4B9999;
}
.importacao{
  background: #FFF3F1;
  color: #FF4838;
}
.propostas{
  background: #E8F1FF;
  color: #7C6DD9;
}
</style>