<template>
  <div class="">
    <Sidebar ></Sidebar>
    <div class="main">
      <Navbar :page="'Dashboard'"></Navbar>
      <Acesso />
    </div>

  </div>
</template>
<script>
import Sidebar from '@/components/Sidebar'
import Navbar from "@/components/Navbar"
import Acesso from "@/components/Acesso"

export default{
  components:{
    Sidebar,
    Navbar,
    Acesso,
  },
  created() {
    console.log(this.$route.path);
  }
}
</script>

<style :scoped>
.main{
  width: calc(100vw - 240px);
  height: 100vh;
  position: absolute;
  right: 0px;
  top: 0;
  background-color: #ffffff !important;
}

</style>