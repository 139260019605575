<template>
  <div class="">
    <BlueSide></BlueSide>
    <FormSide :page="'redefina'" />
  </div>
</template>
<script>
import BlueSide from '@/components/BlueSide'
import FormSide from '@/components/FormSide'
export default{
  components:{
    BlueSide,
    FormSide
  }
}
</script>