import Vue from "vue";
import VueRouter from "vue-router"

import Login from "@/views/Login"
import Ativa from "@/views/Ativa"
import Defina from "@/views/Defina"
import Recupera from "@/views/Recupera"
import Redefina from "@/views/Redefina"
import Dashboard from "@/views/Dashboard"
import Usuarios from "@/views/Usuarios"
import Servidores from "@/views/Servidores"
import Perfil from "@/views/Perfil"

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'login',
        component: Login,
        beforeEnter: (to, from, next) => {
            let token = sessionStorage.getItem('token')
            if(!token){
                next()
            }else{
                next('/dashboard')
            }

        }
    },
    {
        path: '/solicitar-acesso',
        name: 'colicitarAcesso',
        component: Ativa,
        beforeEnter: (to, from, next) => {
            let token = sessionStorage.getItem('token')
            if(!token){
                next()
            }else{
                next('/dashboard')
            }

        }
    },
    {
        path: '/definir-senha',
        name: 'definirSenha',
        component: Defina,
        beforeEnter: (to, from, next) => {
            let token = sessionStorage.getItem('token')
            if(!token){
                next()
            }else{
                next('/dashboard')
            }

        }
    },
    {
        path: '/recuperar-senha',
        name: 'recuperarSenha',
        component: Recupera,
        beforeEnter: (to, from, next) => {
            let token = sessionStorage.getItem('token')
            if(!token){
                next()
            }else{
                next('/dashboard')
            }

        }
    },
    {
        path: '/redefinir-senha',
        name: 'redefinirSenha',
        component: Redefina,
        beforeEnter: (to, from, next) => {
            let token = sessionStorage.getItem('token')
            if(!token){
                next()
            }else{
                next('/dashboard')
            }

        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        beforeEnter: (to, from, next) => {
            let token = sessionStorage.getItem('token')
            if(!token){
                next('/login')
            }else{
                next()
            }

        }
    },
    {
        path: '/usuarios',
        name: 'usuarios',
        component: Usuarios,
        beforeEnter: (to, from, next) => {
            let token = sessionStorage.getItem('token')
            if(!token){
                next('/login')
            }else{
                next()
            }

        }
    },
    {
        path: '/servidores',
        name: 'servidores',
        component: Servidores,
        beforeEnter: (to, from, next) => {
            let token = sessionStorage.getItem('token')
            if(!token){
                next('/login')
            }else{
                next()
            }

        }
    },
    {
        path: '/perfil',
        name: 'perfil',
        component: Perfil,
        beforeEnter: (to, from, next) => {
            let token = sessionStorage.getItem('token')
            if(!token){
                next('/login')
            }else{
                next()
            }

        }
    },
]

const router = new VueRouter({
    mode: "history",
    routes,
});

router.beforeEach((to, from, next) => {
    let token = sessionStorage.getItem('token')
    if(!token){
        if(to.path == '/') return router.push({name:'login'})
    }else{
        if(to.path == '/') return router.push({name:'dashboard'})
    }
    next()
})

export default router