<template>
<div>
  <b-navbar>
    <div class="barra">
      <div>
        <b-navbar-brand href="#"><h3>{{page}}</h3></b-navbar-brand>
      </div>
      <div>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item-dropdown :text="'Olá, '+ user" right>
              <b-dropdown-item @click="handleLogout()">Sair</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>
        </b-collapse>
      </div>
    </div>
  </b-navbar>
</div>


</template>
<script>
export default {
  data(){
    return{
      user: sessionStorage.getItem('name')
    }
  },
  methods:{
    handleLogout(){
      sessionStorage.setItem('name', '')
      sessionStorage.setItem('id', '')
      sessionStorage.setItem('token', '')
      return this.$router.push({name: 'Login'})
    }
  },
  props:{
    page:{
      type: String
    }
  }
}
</script>
<style :scoped>

.barra{
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 3rem;
}

.barra div{
  display: flex;
  align-items: center;
  padding: 0 10px;
}

</style>