<template>
  <div class="">
    <Sidebar ></Sidebar>
    <div class="main">
      <Navbar :page="'Perfil do usuário'"></Navbar>
      <div class="painel">
        <div class="head">
          <div class="limitador"><b-form-input type="search"></b-form-input></div>

          <button class="btn btn-primary">+ Adicionar Perfil</button>
        </div>
        <table class="table table-striped">
          <thead>
          <th>Nome</th>

          <th>Opções</th>
          </thead>
          <tbody>
          <tr>
            <td>Gerente</td>

            <td></td>
          </tr>
          <tr>
            <td>Servidor</td>

            <td></td>
          </tr>
          </tbody>
        </table>
      </div>

    </div>

  </div>
</template>
<script>
import Sidebar from '@/components/Sidebar'
import Navbar from "@/components/Navbar"

export default{
  components:{
    Sidebar,
    Navbar,

  },
}
</script>

<style :scoped>
.main{
  width: calc(100vw - 240px);
  height: 100vh;
  position: absolute;
  right: 0px;
  top: 0;
  background: #F8F9FC;
}
.painel{
  background: white;
  width: 90%;
  margin: 10px auto;
  padding: 10px 0;
  border-radius: 12px;
}
.head{
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
}

.limitador{
  width: 40%;
}

.pill-h {
  font-size: 0.9em;
  color: #1F9254;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;

  width: 87px;
  height: 30px;

  background: #E0F4F4;
  border-radius: 22px;
}

.pill-d {
  font-size: 0.9em;
  color: #A30D11;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;

  width: 87px;
  height: 30px;

  background: #FBE7E8;
  border-radius: 22px;
}

</style>