<template>
  <div class="">
    <Sidebar ></Sidebar>
    <div class="main">
      <Navbar :page="'Usuários'"></Navbar>
      <div class="painel">
        <div class="head">
          <div class="limitador"><b-form-input type="search"></b-form-input></div>
          <b-modal id="modal-1" hide-footer title="Novo usuário">
            <b-form>
              <b-form-group label="Nome:" label-for="name">
                <b-form-input id="name" v-model="form.name" type="text" placeholder="Insira o nome completo"></b-form-input>
              </b-form-group>
              <b-form-group label="CPF:" label-for="cpf">
                <b-form-input id="cpf" v-mask="'###.###.###-##'" v-model="form.cpf" type="text" placeholder="000.000.000-00"></b-form-input>
              </b-form-group>
              <b-form-group label="Email:" label-for="email">
                <b-form-input id="email" v-model="form.email" type="email" placeholder="seuemail@provedor.com"></b-form-input>
              </b-form-group>
              <b-form-group label="Telefone:" label-for="phone">
                <b-form-input id="phone" v-mask="'(##) # #### - ####'" v-model="form.phone" type="text" placeholder="(00) 0 0000 - 0000"></b-form-input>
              </b-form-group>
              <b-form-group label="Data de nascimento:" label-for="birth_date">
                <b-form-input id="birth_date"  v-model="form.birth_date" type="date"></b-form-input>
              </b-form-group>
            </b-form>
            <div class="btns">
              <b-button @click="handleUserFormSubmit()" variant="primary">Cadastrar usuário</b-button>
              <b-button @click="closeModal('modal-1')" variant="outline-danger">Cancelar</b-button>
            </div>
          </b-modal>
          <b-modal id="modal-2" hide-footer title="Exibir usuário">
            <b-form>
              <b-form-group label="Nome:" label-for="name">
                <b-form-input id="name" v-model="usuario.name" type="text"></b-form-input>
              </b-form-group>
              <b-form-group label="CPF:" label-for="cpf">
                <b-form-input id="cpf" v-mask="'###.###.###-##'" v-model="usuario.cpf" type="text" placeholder="000.000.000-00"></b-form-input>
              </b-form-group>
              <b-form-group label="Email:" label-for="email">
                <b-form-input id="email" v-model="usuario.email" type="email" placeholder="seuemail@provedor.com"></b-form-input>
              </b-form-group>
              <b-form-group label="Telefone:" label-for="phone">
                <b-form-input id="phone" v-mask="'(##) # #### - ####'" v-model="usuario.phone" type="text" placeholder="(00) 0 0000 - 0000"></b-form-input>
              </b-form-group>
              <b-form-group label="Data de nascimento:" label-for="birth_date">
                <b-form-input id="birth_date"  v-model="usuario.birth_date" type="date"></b-form-input>
              </b-form-group>
            </b-form>
            <div class="btns">
              <b-button @click="closeModal('modal-2')" variant="outline-danger">Cancelar</b-button>
            </div>
          </b-modal>
          <b-modal id="modal-3" hide-footer title="Editar usuário">
            <b-form>
              <b-form-group label="Nome:" label-for="name">
                <b-form-input id="name" v-model="usuario.name" type="text"></b-form-input>
              </b-form-group>
              <b-form-group label="CPF:" label-for="cpf">
                <b-form-input id="cpf" v-mask="'###.###.###-##'" v-model="usuario.cpf" type="text" placeholder="000.000.000-00"></b-form-input>
              </b-form-group>
              <b-form-group label="Email:" label-for="email">
                <b-form-input id="email" v-model="usuario.email" type="email" placeholder="seuemail@provedor.com"></b-form-input>
              </b-form-group>
              <b-form-group label="Telefone:" label-for="phone">
                <b-form-input id="phone" v-mask="'(##) # #### - ####'" v-model="usuario.phone" type="text" placeholder="(00) 0 0000 - 0000"></b-form-input>
              </b-form-group>
              <b-form-group label="Data de nascimento:" label-for="birth_date">
                <b-form-input id="birth_date"  v-model="usuario.birth_date" type="date"></b-form-input>
              </b-form-group>
            </b-form>
            <div class="btns">
              <b-button @click="handleUserEdit()" variant="primary">Alterar usuário</b-button>
              <b-button @click="closeModal('modal-3')" variant="outline-danger">Cancelar</b-button>
            </div>
          </b-modal>
          <button v-b-modal.modal-1 class="btn btn-primary">+ Adicionar Usuário</button>
        </div>
        <table class="table table-striped">
          <thead>
            <th>Nome</th>
            <th>CPF</th>
            <th>Status</th>
            <th>Opções</th>
          </thead>
          <tbody>
            <tr v-for="user in usuarios" :key="user.id">
              <td>{{ user.name }}</td>
              <td>{{ user.cpf }}</td>
              <td v-if="user.status"><div class="pill-h">Habilitado</div></td>
              <td v-if="!user.status"><div class="pill-d">Desabilitado</div></td>
              <td><b-icon-eye @click="setUser(user, 'modal-2')"></b-icon-eye>
              <b-icon-file-earmark-richtext @click="setUser(user, 'modal-3')"></b-icon-file-earmark-richtext>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>

  </div>
</template>
<script>
import Sidebar from '@/components/Sidebar'
import Navbar from "@/components/Navbar"

export default{
  components:{
    Sidebar,
    Navbar,
  },
  data() {
    return{
      usuarios: [],
      usuario: {},
      form: {
        name: '',
        cpf: '',
        email: '',
        phone: '',
        password: '0000',
        birth_date: ''
      }
    }
  },
  methods: {
    loadUsers() {
      this.usuario = []
      this.$http.get('usuarios').then(response => {
        this.usuarios = response.data.data
      }).catch(error => console.log(error))
    },
    setUser(user, modal) {
      this.usuario = user
      this.$bvModal.show(modal)
    },
    handleUserFormSubmit() {
      let config = {
        headers: {'Content-Type': 'multipart/form-data'}
      }
      this.$http.post('usuario', this.form, config).then(response => {
        console.log(response)
        this.$swal({
          title: response.data.message,
          icon: "success",
        });
        this.$bvModal.hide('modal-1')
        this.loadUsers()
      }).catch(error => console.log(error))
    },
    handleUserEdit() {
      let config = {
        headers: {'Content-Type': 'multipart/form-data'}
      }
      this.$http.put(`usuario/{this.usuario.id}`, this.usuario, config).then(response => {
        console.log(response)
        this.$swal({
          title: response.data.message,
          icon: "success",
        });
        this.$bvModal.hide('modal-3')
        this.loadUsers()
      }).catch(error => console.log(error))
    },
    closeModal(modal) {
      this.$bvModal.hide(modal)
    }
  },
  mounted() {
    this.loadUsers()
  }
}
</script>

<style :scoped>
.main{
  width: calc(100vw - 240px);
  height: 100vh;
  position: absolute;
  right: 0px;
  top: 0;
  background: #F8F9FC;
}
.painel{
  background: white;
  width: 90%;
  margin: 10px auto;
  padding: 10px 0;
  border-radius: 12px;
}
.head{
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
}

.limitador{
  width: 40%;
}

.pill-h {
  font-size: 0.9em;
  color: #1F9254;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;

  width: 87px;
  height: 30px;

  background: #E0F4F4;
  border-radius: 22px;
}

.pill-d {
  font-size: 0.9em;
  color: #A30D11;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;

  width: 87px;
  height: 30px;

  background: #FBE7E8;
  border-radius: 22px;
}
.btns{
  display: flex;
  flex-direction: row-reverse;
}

</style>