import Vue from "vue";
import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_ROOT_API;

axios.interceptors.request.use(
    async (config) => {
        config.headers = {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        };
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

Vue.use({
    install(Vue) {
        Vue.prototype.$http = axios;
    },
});
