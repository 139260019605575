<template>
  <div class="formside">
    <div class="header" v-if="page === 'login'">
      <img src="../assets/image19.png" alt="Consignei">
    </div>
    <div class="header completo" v-if="page !== 'login'">
      <img src="../assets/image19.png" alt="Consignei">
      <p>Já possui uma conta? <router-link to="/login" class="login-link">Logue-se</router-link></p>
    </div>

    <!--login-->
    <div class="body" v-if="page === 'login'">
      <h3>Acesse sua conta</h3>
      Faça login para continuar
      <div class="form">
        <b-form @submit.prevent="handleLogin">
          <label>
            CPF
          </label>
          <b-form-input v-mask="'###.###.###-##'" v-model="cpf" placeholder="Digite seu CPF" required/>
          <br />
          <label>
            Senha
          </label>
          <b-form-input type="password" v-model="password" placeholder="Digite a sua senha" required/>
          <div v-if="errors" class="erroForm">
            <b-alert show variant="danger">{{errorDescricao}}</b-alert>
          </div>

          <div class="forgot">
            <router-link to="/recuperar-senha">Esqueceu sua senha?</router-link>
          </div>
          <b-button type="submit" class="botao">Entrar</b-button>
        </b-form>
      </div>
    </div>

    <!--ativar-->
    <div class="body" v-if="page === 'ativa'">
      <h3>Ative sua conta</h3>
      Insira seu e-mail para receber instruções sobre definir sua senha de acesso.
      <div class="form">
        <b-form>
          <label>
            E-mail
          </label>
          <b-form-input type="email" placeholder="Insira seu e-mail" required/>
          <br />


          <b-button class="botao">Enviar e-mail de Cadastro</b-button>
        </b-form>
      </div>
    </div>

    <!--Defina -->
    <div class="body" v-if="page === 'defina'">
      <h3>Defina sua senha</h3>
      Escolha sua senha de acesso ao sistema
      <div class="form">
        <b-form>
          <label>
            Senha
          </label>
          <b-form-input type="password" placeholder="Insira sua senha" required/>
          <br />
          <label>
            Repita sua senha
          </label>
          <b-form-input type="password" placeholder="Digite sua senha novamente" required/>
          <br>
          <b-button class="botao">Definir Senha</b-button>
        </b-form>
      </div>
    </div>

    <!--recupera-->
    <div class="body" v-if="page === 'recupera'">
      <h3>Recupere sua senha</h3>
      Insira seu e-mail para receber instruções sobre como trocar sua senha por uma nova.
      <div class="form">
        <b-form>
          <label>
            E-mail
          </label>
          <b-form-input type="email" placeholder="Insira seu e-mail" required/>
          <br />


          <b-button class="botao">Enviar e-mail de recuperação</b-button>
        </b-form>
      </div>
    </div>

    <!--redefina-->
    <div class="body" v-if="page === 'redefina'">
      <h3>Redefina sua senha</h3>
      Escolha sua senha de acesso ao sistema
      <div class="form">
        <b-form>
          <label>
            Senha
          </label>
          <b-form-input type="password" placeholder="Insira sua senha" required/>
          <br />
          <label>
            Repita sua senha
          </label>
          <b-form-input type="password" placeholder="Digite sua senha novamente" required/>

          <br>
          <b-button class="botao">Redefinir Senha</b-button>
        </b-form>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  props:{
    page:{
      type: String
    }
  },
  data () {
    return {
      cpf: "",
      password: "",
      errors: false,
      errorDescricao: ""
    }
  },
  methods: {
    handleLogin() {
      let login = this.cpf.replaceAll('.', '')
      login = login.replaceAll('-', '')
      let data = {
        cpf: login,
        password: this.password
      }
      let config = {
        headers: {'Content-Type': 'multipart/form-data'}
      }
      this.$http.post('auth/login', data, config).then(response => {
        sessionStorage.setItem('name', response.data.user.name)
        sessionStorage.setItem('id', response.data.user.id)
        sessionStorage.setItem('token', response.data.access_token)
        return this.$router.push({name: 'Dashboard'})
      }).catch(error => {
        this.errors = true
        this.errorDescricao = error.response.data.error
      })
    }
  }
}
</script>
<style :scoped>
.formside{
  position: fixed;
  top: 0;
  right: 0;
  width: 55%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
}

img{
  width: 131px;
  height: 32px;
}

.header{
  width: 80%;
  margin-top: 20px;
}

.completo{
  display: flex;
  justify-content: space-between;
}

.login-link{
  color: #0053C8;
  text-decoration: none;
}

.body{
  width: 80%;
  margin-top: 60px;
}

.form{
  margin-top: 40px;
}

.erroForm{
  padding-top: 10px;
}

.forgot{
  width: 100%;
  padding: 5px;
  display: flex;
  justify-content: end;
  font-size: 14px;
  color: #0053C8;
}

.botao{
  width: 100%;
  background-color: #0053C8 !important;
}

.botao:hover{
  background-color: #2275e2 !important;
}

@media(max-width: 969px){
  .formside{
    width: 100%;
  }
}
</style>